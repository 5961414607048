import type { CASE_TYPE, CaseParams, CourtParams } from "@/shared/utils/types";

export interface PaginatedResponse<T> {
  count: number;
  page: number;
  rows: number;
  result: T[];
}

export interface JudgeLinks {
  root: string[];
  card: string[];
  resolutionText: string[];
}

export interface JudgeHeader {
  articles: string[];
  caseNumber: string;
  region: number;
  courtName: string;
  processType: string;
  startDate: string;
  endDate: string;
  review: number;
  papersPretty: string[];
  papers: string[];
  judge: null | string;
  resolution: string;
  resolutionKey?: string;
  link?: string;
  links: JudgeLinks;
  result?: string;
}

export interface JudgeFace {
  face: string;
  role: string;
  roleName: string;
  papersPretty: string[];
  papers: string[];
}

export interface JudgeCaseProgressItem {
  name: string;
  resolution?: string;
  cause?: string;
  date?: string;
  time?: string;
}

export interface JudgeResultItem {
  id: string;
  header: JudgeHeader;
  faces: JudgeFace[];
  caseProgress: null | JudgeCaseProgressItem[];
}

export type JudgeResponse = PaginatedResponse<JudgeResultItem>;

export enum SortType {
  ASC = "asc",
  DESC = "desc",
  IGNORE = "ignore",
}
export type SortFields = "startDate" | "endDate";

export interface SortParam {
  field: SortFields;
  sortType: SortType;
}

export class SortParams {
  params: Record<SortFields, SortType>;

  constructor(
    params: Record<SortFields, SortType> = {
      startDate: SortType.IGNORE,
      endDate: SortType.IGNORE,
    },
  ) {
    this.params = params;
  }

  public paramsToList(): SortParam[] {
    return (Object.keys(this.params) as SortFields[]).map((key) => ({
      field: key,
      sortType: this.params[key],
    }));
  }
}

export interface QueryParams {
  page: number;
  rows: number;
  hasResolution: boolean;
  sortParams: SortParam[];
  filterText: string;
}

export interface UniQueryParams {
  text: string;
  region: string | null;
  caseType: CASE_TYPE[];
}

export interface UniQueryRequest {
  text: string;
}

export interface QueryFilters {
  page: number;
  rows: number;
  hasResolution: boolean;
  sortParams: SortParam[];
  filterText: string;
  text: string;
}

export interface UniQueryFilters extends QueryFilters {
  caseType: CASE_TYPE[];
  region: string | null;
}

export interface AdvancedQueryFilters extends QueryFilters {
  courtParams: CourtParams;
  caseParams: CaseParams;
}

export enum USER_ROLES {
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface LoginResponse {
  authenticated: boolean;
  username?: string;
  roles: USER_ROLES[];
}

export interface LoginState extends LoginResponse {
  error: boolean;
}

export interface LoginParams {
  login: string;
  password: string;
}

export interface DemoParams {
  email: string;
  phone: string;
}
